(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name trek.filter:dollars
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('trek')
    .filter('dollars', dollars);

  function dollars() {
    return function (input) {
      if (!input) {
        return '';
      }
      return input / 100;
    };
  }
}());
